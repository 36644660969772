.item-container
  max-width: 280px
  opacity: 0
  animation-fill-mode: forwards
  animation-name: pop-Up
  animation-duration: .3s
  position: relative
  margin: 1em
  background: white
  display: flex
  box-shadow: 1px 1px 8px black
  transition: all .2s
  //padding: .5em .5em 0 1em
  &:hover
    box-shadow: 3px 1px 20px black
    transition: all .1s
  img
    object-fit: cover
    width: 100%
    height: 55%
    min-width: 190px
    border-radius: 0 0 10px 0
  .utils
    z-index: 2
    text-align: center
    i
      margin: 0 .5em
  .item-price
    font-size: 1.4em
  .item-name
    color: #1e1e23
  .fa
    margin: 0 .5em
  .bottom
    margin: .5em 0
    display: flex
    align-items: center
    justify-content: center
  .divider
    z-index: 1
  .main
    z-index: 2
  .stars
    display: grid

  .custom-shape-divider-top-1663576759
    position: absolute
    top: 0
    left: 0
    width: 100%
    overflow: hidden
    line-height: 0
  .custom-shape-divider-top-1663576759 svg
    position: relative
    display: block
    width: calc(100% + 1.3px)
    height: 51px
  .custom-shape-divider-top-1663576759 .shape-fill
    fill: #eae8e8

  .custom-shape-divider-bottom-1663576949
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    overflow: hidden
    line-height: 0
    transform: rotate(180deg)

  .custom-shape-divider-bottom-1663576949 svg
    position: relative
    display: block
    width: calc(100% + 1.3px)
    height: 51px


  .custom-shape-divider-bottom-1663576949 .shape-fill
    fill: #1e1e23





