.navigation {
  position: absolute;
  top: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  height: 120px;
}
.navigation .navigation-wrapper {
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigation .navigation-wrapper .right-icons {
  display: flex;
  align-items: center;
  color: white;
}
.navigation .navigation-wrapper .right-icons .user-icon {
  margin-left: 1em;
}
.navigation .navigation-wrapper .right-icons .user-icon img {
  border-radius: 50%;
  width: 60px;
}
.navigation .navigation-wrapper .logo-img {
  z-index: 100;
}
.navigation .navigation-wrapper .logo-img img {
  height: 135%;
}
.navigation .navigation-wrapper .menu-icon {
  color: white;
  z-index: 100;
  cursor: pointer;
  font-weight: normal;
}
.navigation .navigation-wrapper .logo-img img {
  width: 130px;
}
.navigation .navigation-wrapper .logo-text {
  font-weight: bold;
  line-height: 0;
}
.navigation .navigation-wrapper .cart-icon {
  color: whitesmoke;
}
.navigation .navigation-wrapper .cart-icon img {
  width: 40px;
}

.custom-shape-divider-top-1663860861 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1663860861 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1663860861 .shape-fill {
  fill: #23232a;
}

@media screen and (max-width: 500px) {
  .navigation .right-icons .user-icon {
    margin: 0;
  }
}

