.item-review-container
  text-align: left
  background: white
  padding: 1em
  margin: 2em 0
  border-radius: 10px
  box-shadow: 1px 1px 1px black
  .display-name
    font-size: 1.2em
  .review-title
    font-size: 1.5em
    border: 1px solid black
    padding: .7em
    width: fit-content
  .item-content
    display: flex
    justify-content: space-between
  .item-img
    text-align: center
    margin-top: -4em
    img
      border-radius: 10px
      width: 100px
      height: 130px
  .review-header
    display: flex
    img
      border-radius: 50%
      margin: 0 1em 0 0
      width: 100px