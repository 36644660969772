$fontAlt: 'Kalam', cursive


.home-page-container
  .landing
    display: flex
    justify-content: center
    align-items: center
    text-align: left
    color: whitesmoke
    background: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,.7)), url("../../utils/imgs-landing-page/HeroBG3.jpg")
    background-size: cover
    background-repeat: no-repeat
    background-attachment: fixed
    height: 100vh
    background-position: 20% 50%
    .text
      width: 75%
      margin: 0 auto
      line-height: 0
      color: whitesmoke
      display: flex
      justify-content: center
      flex-direction: column
      .title-container
        font-weight: 300
        font-size: 4em
        text-transform: uppercase
      .sub-title-container
        font-weight: 300
        font-size: 1.45em
        text-transform: uppercase
      .call-to-action-container
        display: flex
        width: fit-content
        flex-direction: column
        align-items: center
        margin-top: -3em
        .mouse-icon
          margin-top: .2em
          animation-name: up-down
          animation-duration: 1s
          animation-iteration-count: infinite
        .button-landing-page
          margin-top: 4em
      .title-details
        font-weight: 300
        text-align: justify
        max-width: 480px
        font-family: $fontAlt
        font-size: 2em
        line-height: 25px
  .home-page-wrapper
    width: 80%
    margin: 0 auto
    .benefits
      margin: 1.3em 0
  .home-page-benefits
    .benefits
      list-style-type: none

@media screen and (max-width: 500px)
  html
    font-size: 12px
  .title-container
    font-size: 3.5em !important
  .text
    width: 85% !important
  .landing
    background-position: 50% !important


@keyframes up-down
  0%
    transform: translateY(0)
  50%
    transform: translateY(60%)
  100%
    transform: translateY(0)