.benefits
  display: flex
  justify-content: center
  .benefit
    max-width: 400px
    border-radius: 10px
    margin-left: .5em
    margin-top: .7em
    padding: 1em
    text-align: justify
    .benefit-header
      text-align: center
      display: flex
      justify-content: center
      gap: 1em
    img
      width: 80px
  .benefit1
    background: linear-gradient(to right, whitesmoke, orange)
  .benefit2
    background: #6ec4da
  .benefit3
    background: linear-gradient(to left, whitesmoke, lightgreen)

@media screen and (max-width: 1000px)
  .benefits
    flex-direction: column
