.latest-addition-container {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.latest-addition-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

