.footer-container
  margin-top: 7em
  position: relative
  color: whitesmoke
  background: #1e1e23
  text-align: center
  display: flex
  justify-content: center
  padding: 1em
  .footer-category-container
    h2
      margin: .3em 1em
      color: orange
    ul
      list-style-type: none
      li
        margin: .4em 0


.custom-shape-divider-top-1666795932
  position: absolute
  top: -130px
  left: 0
  width: 100%
  overflow: hidden
  line-height: 0
  rotate: 180deg


.custom-shape-divider-top-1666795932 svg
  position: relative
  display: block
  width: calc(100% + 1.3px)
  height: 150px


.custom-shape-divider-top-1666795932 .shape-fill
  fill: #1e1e23

