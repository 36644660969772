.pop-up-container {
  position: fixed;
  text-align: center;
  z-index: 100;
  left: 20px;
  top: 20px;
  animation-name: slideRight;
  animation-duration: 0.5s;
}
.pop-up-container .pop-up {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: white;
  padding: 1em;
}
.pop-up-container .pop-up .fa {
  margin-top: 0.2em;
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

