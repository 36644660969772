.category-shop-page-container {
  width: 80%;
  margin: 3em auto;
  padding-top: 10em;
}

.category-items-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  text-align: center;
}

.sort-options-container {
  display: flex;
  flex-direction: row;
}

.highlighted-items {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

select, input {
  margin: 0 1em;
  background: transparent;
  border: 1px solid black;
  padding: 0.7em;
  font-family: "Montserrat Alternates", sans-serif;
}

@media screen and (max-width: 700px) {
  .sort-options-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  .category-items-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .category-items-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .category-items-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (min-width: 1500px) {
  .category-items-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (min-width: 1900px) {
  .category-items-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media screen and (min-width: 2300px) {
  .category-items-container {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media screen and (min-width: 3000px) {
  .category-items-container {
    grid-template-columns: repeat(10, 1fr);
  }
}

