.navigation
  position: absolute
  top: 0
  width: 100vw
  background: rgba(0,0,0,.3)
  height: 120px
  .navigation-wrapper
    width: 75%
    margin: 0 auto
    display: flex
    justify-content: space-between
    align-items: center
    .right-icons
      display: flex
      align-items: center
      color: white
      .user-icon
        //width: 40px
        //text-align: center
        margin-left: 1em
        img
          border-radius: 50%
          width: 60px

    .logo-img
      z-index: 100
      img
        height: 135%
    .menu-icon
      color: white
      z-index: 100
      cursor: pointer
      font-weight: normal
    .logo-img
      img
        width: 130px
    .logo-text
      font-weight: bold
      line-height: 0
    .cart-icon
      color: whitesmoke
      img
        width: 40px

.custom-shape-divider-top-1663860861
  position: absolute
  top: 0
  left: 0
  width: 100%
  overflow: hidden
  line-height: 0

.custom-shape-divider-top-1663860861 svg
  position: relative
  display: block
  width: calc(100% + 1.3px)
  height: 150px
  transform: rotateY(180deg)

.custom-shape-divider-top-1663860861 .shape-fill
  fill: rgb(35, 35, 42)

@media screen and (max-width: 500px)
  .navigation
    .right-icons
      .user-icon
        margin: 0