.checkout-item-container
  background: white
  padding: 1em
  border-radius: 10px
  display: flex
  flex-direction: column
  width: fit-content
  margin: 1em
  text-align: center
  align-items: center
  img
    border-radius: 10px
  .basic-info
    margin: 0 1em
    text-align: center
  .fa
    margin: 0 .4em
  .quantity-utils
    display: flex
    align-items: center
  .money-icon
    width: 50px
    margin: 0 .8em 0 0
  .quantity-container
    display: flex
    justify-content: center
    align-items: center