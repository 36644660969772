.parallax
  filter: grayscale(1)
  transition: .2s
  background-attachment: fixed
  height: 40vh
  background-size: cover
  background-repeat: no-repeat
  color: whitesmoke
  display: flex
  justify-content: center
  align-items: center
  font-weight: bold
  font-size: 4em
  overflow: hidden
  .content-container p
    transition: all .3s
  //border: 10px solid whitesmoke
  &:hover
    filter: grayscale(0)
    transition: all .2s
  &:hover > .content-container p
    transition: all .3s
    transform: scale(1.3)
  .content-container
    border: 5px solid whitesmoke
    padding: .5em
    text-align: center
    line-height: 0
    img
      width: 100px