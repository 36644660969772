.profile-container {
  width: 80%;
  text-align: center;
  margin: 1em auto;
  padding-top: 10em;
}
.profile-container img {
  width: 50%;
}
.profile-container .profile-basic-info-container {
  display: flex;
  flex-direction: column;
}
.profile-container .profile-basic-info-container img {
  border-radius: 50%;
  width: 100%;
}
.profile-container .section {
  margin: 2em 0;
  display: flex;
  justify-content: center;
}
.profile-container .favourite-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.profile-container .profile-menu {
  background: white;
  padding: 1em;
  list-style-type: none;
  border-radius: 10px;
}
.profile-container .profile-menu .profile-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.4em;
  cursor: pointer;
  padding: 0.5em;
  transition: all 0.2s;
}
.profile-container .profile-menu .profile-menu-item:hover {
  background: orange;
  border-radius: 10px;
  transition: all 0.2s;
}
.profile-container .profile-menu .profile-menu-item .fa {
  margin-right: 0.3em;
}

