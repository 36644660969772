.highlighted-item-container
  margin: 0 .5em
  img
    width: 300px
    height: 350px
    object-fit: cover
  .text
    width: 90%
    margin: 0 auto
    text-align: center
    line-height: 10px
  //background: red