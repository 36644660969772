.button-container {
  position: relative;
  font-family: "Montserrat Alternates", sans-serif;
  background: transparent;
  padding: 1em 1em 1em 1em;
  cursor: pointer;
}
.button-container:hover .divider-top {
  height: 15px;
  transition: 0.2s;
}
.button-container .divider-top {
  transition: 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  background: #1e1e23;
  border-radius: 0 0 5px 5px;
  height: 3px;
  width: 100%;
}

