.shop-container {
  width: 80%;
  margin: 1em auto;
  padding-top: 10em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.shop-container .shop-container-row {
  display: flex;
  align-items: center;
  margin: 1.4em 0;
}
.shop-container .latest-addition-text {
  margin: 0 1em;
  text-align: left;
  font-size: 1.5em;
}
.shop-container .shop-container-text {
  display: flex;
  flex-direction: column;
}
.shop-container .shop-container-text .category-title {
  margin: 1em;
  color: black;
  font-size: 2em;
  border: 1px solid black;
  padding: 1em;
}

@media screen and (max-width: 1000px) {
  .shop-container .shop-container-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

