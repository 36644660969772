.cart-dropdown-item-container
  display: flex
  margin: 1em 0
  .text-container
    margin: 1em
    text-align: left
  .x-mark
    cursor: pointer
    display: flex
    align-items: center
    justify-content: right