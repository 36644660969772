.product-info-container {
  padding-top: 10em;
  text-align: center;
  width: 70%;
  margin: 3em auto;
}
.product-info-container .reviews-container {
  display: flex;
  gap: 1em;
  justify-content: center;
}
.product-info-container .add-review {
  margin: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.product-info-container .percent-customers {
  border-radius: 10px;
  color: whitesmoke;
  padding: 1em;
  background: url(../../utils/imgs/abstractArt2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.product-info-container .percent-customers .highlighted {
  font-weight: bold;
  font-size: 2.5em;
}
.product-info-container .percent-customers img {
  margin-right: 1em;
  width: 100px;
}
.product-info-container .other-products-container .products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.7em;
}
.product-info-container .other-products-container .title {
  font-size: 2em;
}

.basic-info-container-bg {
  color: white;
  border-radius: 0 0 10px 10px;
  padding: 10em 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(../../utils/imgs/abstractArt.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-size: cover;
}
.basic-info-container-bg .basic-info-container {
  width: 70%;
  margin: 0 auto;
  display: flex;
}
.basic-info-container-bg .basic-info-container img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.basic-info-container-bg .basic-info-container .aside-left {
  display: flex;
}
.basic-info-container-bg .basic-info-container .aside-left .aside {
  margin: 0 2em;
}
.basic-info-container-bg .basic-info-container .aside-left .aside .product-title {
  font-size: 2.3em;
}
.basic-info-container-bg .basic-info-container .aside-left .aside .price {
  font-size: 1.5em;
}
.basic-info-container-bg .heart {
  margin: 0 1em;
}
.basic-info-container-bg .no-user-reviews {
  text-align: center;
}
.basic-info-container-bg .no-user-reviews p {
  margin: 2em 0;
}
.basic-info-container-bg .stars {
  display: flex;
}
.basic-info-container-bg .fa {
  margin: 0 0.3em;
}
.basic-info-container-bg .aside-content {
  width: 50%;
  margin: 0 auto;
  margin-left: 1em;
}
.basic-info-container-bg .aside-content .missing-img {
  object-fit: scale-down;
  width: 100%;
  max-width: 600px;
}

@media screen and (max-width: 1000px) {
  .product-info-container .basic-info-container {
    flex-direction: column;
  }

  .aside-left {
    flex-direction: column;
    color: whitesmoke;
  }

  .aside-content {
    color: whitesmoke;
  }

  .product-info-container {
    width: 85%;
  }
  .product-info-container .reviews-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  .benefits {
    flex-direction: column;
  }
}

