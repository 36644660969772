.leave-review-container
  margin: 0 auto
  width: 100%
  input, textarea
    background: transparent
    border: 1px solid black
    font-family: "Montserrat Alternates", sans-serif
    padding: 1em
    margin: .4em 0
  form
    display: flex
    flex-direction: column