.order-container
  background: white
  padding: 1em
  border-radius: 10px
  transition: all .2s
  margin: 1em 0
  .buttons-container
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 1em
  &:hover
    box-shadow: 5px 5px 10px black
    transition: all .2s
  .items-info
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
  .order-items
    display: flex
    flex-wrap: wrap
    flex-direction: column
    margin: 1em
    justify-content: center
    align-items: center
    gap: 1em
    img
      border-radius: 50%
      width: 160px
      height: 160px
