.banner-container
  padding-top: 2em
  background-repeat: no-repeat
  background-attachment: fixed
  background-size: cover
  height: 50vh
  display: flex
  justify-content: center
  align-items: center
  p
    padding: .4em
    border: 10px solid whitesmoke
    font-weight: bold
    font-size: 3em
    color: whitesmoke