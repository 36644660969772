.favourites-page-container
  width: 80%
  margin: 0 auto
  display: flex
  align-items: center
  flex-direction: column
.favourite-items-container
  display: grid
  grid-template-columns: 1fr 1fr
  align-items: center
  justify-content: center
  text-align: center

@media screen and (max-width: 1000px)
  .favourite-items-container
    grid-template-columns: repeat(3, 1fr)

@media screen and (max-width: 800px)
  .favourite-items-container
    grid-template-columns: repeat(2, 1fr)

@media screen and (max-width: 600px)
  .favourite-items-container
    grid-template-columns: repeat(1, 1fr)

@media screen and (min-width: 1500px)
  .favourite-items-container
    grid-template-columns: repeat(5, 1fr)

@media screen and (min-width: 1900px)
  .favourite-items-container
    grid-template-columns: repeat(6, 1fr)

@media screen and (min-width: 2300px)
  .favourite-items-container
    grid-template-columns: repeat(8, 1fr)

@media screen and (min-width: 3000px)
  .favourite-items-container
    grid-template-columns: repeat(10, 1fr)