.navigation-extended
  background: linear-gradient(to bottom, #18181d, #151518)
  position: absolute
  top: 0
  left: 0
  min-height: 100vh
  min-width: 100vw
  z-index: 101
  display: flex
  .bottom-section
    display: flex
    justify-content: center
    align-items: center
    .user-icon
      margin: 0 1em
      img
        width: 100px
        border-radius: 50%
  .top-section
    text-align: center
    font-weight: bold
    font-size: 3.5em
    color: whitesmoke
    .menu-close-btn
      width: 100px
      transition: .2s
    &:hover
      color: #46464d
      transition: .15s
  flex-direction: column
  .navigation-container
    margin: 0
    color: whitesmoke
    display: flex
    align-items: center
    flex-wrap: wrap
    justify-content: center
    overflow: hidden
    animation-name: open-top
    animation-duration: .2s
    border-radius: 0 10px 10px 0
    padding: 1em
    list-style-type: none
    li
      margin: .5em
      .link-helper
        display: flex
        align-items: center
        flex-direction: column
        transition: .2s
        padding: .5em
        border-radius: 10px
        p
          font-size: 2em
        &:hover
          background: #46464d
          transition: .2s
        .navigation-icon
          margin: 10px 0
          width: 15em
          max-width: 250px




@keyframes open-top
  0%
    transform: translateY(-100%)
  100%
    transform: translateY(0)
