.checkout-page-container
  .master
    .checkout-items
      display: flex
      flex-wrap: wrap
      justify-content: center
  .addresses-selector
    .address-container
      display: flex
  width: 80%
  margin: 10em auto
  //display: flex
  //justify-content: space-between
  .aside
    border: 1px solid white
    background: white
    padding: 1em
    border-radius: 10px
    height: fit-content
    position: fixed
    right: 50px
    top: 20%
    .total-title
      font-size: 2em
.total-price-container
  font-size: 1.7em
  font-weight: bold


@media screen and (max-width: 1000px)
  .checkout-page-container
    .aside
      position: relative
      text-align: center