.latest-addition-item-container
  margin: 0 1em
  opacity: 0
  animation-fill-mode: forwards
  animation-name: pop-Up
  animation-duration: .3s
  img
    width: 100%
    height: 230px

@keyframes pop-Up
  0%
    opacity: 0
    transform: scale(0)
  100%
    opacity: 1
    transform: scale(1)