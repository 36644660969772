.leave-review-container {
  margin: 0 auto;
  width: 100%;
}
.leave-review-container input, .leave-review-container textarea {
  background: transparent;
  border: 1px solid black;
  font-family: "Montserrat Alternates", sans-serif;
  padding: 1em;
  margin: 0.4em 0;
}
.leave-review-container form {
  display: flex;
  flex-direction: column;
}

