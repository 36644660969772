.profile-info-container
  //background: white
  border-radius: 10px
  text-align: center
  display: flex
  flex-wrap: wrap
  justify-content: center
  gap: 1.5em
  .profile-reviews-container
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 1em

@media screen and (max-width: 1500px)
  .profile-info-container
    .profile-reviews-container
      grid-template-columns: 1fr 1fr
@media screen and (max-width: 1000px)
  .profile-info-container
    .profile-reviews-container
      grid-template-columns: 1fr