.auth-page-container
  background: url("../../utils/imgs-landing-page/HeroBG2.jpg")
  background-size: cover
  background-repeat: no-repeat
  background-position: center left
  display: flex
  align-items: center
  height: 100vh
.auth-container
  text-align: center
  padding: 1em
  width: fit-content
  display: flex
  background: white
  border-radius: 10px 0 10px 0
  flex-direction: column
  align-items: center
  margin-left: 10em
  .login-option
    display: flex
    justify-content: center
    align-content: center
    .fa
      margin-right: .3em
  img
    margin: 1em 0
