body {
  background: whitesmoke;
  font-family: "Montserrat Alternates", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

