.profile-container
  width: 80%
  text-align: center
  margin: 1em auto
  padding-top: 10em
  img
    width: 50%
  .profile-basic-info-container
    img
      border-radius: 50%
      width: 100%
    display: flex
    flex-direction: column
  .section
    margin: 2em 0
    display: flex
    justify-content: center
  .favourite-items
    display: grid
    grid-template-columns: 1fr 1fr
  .profile-menu
    background: white
    padding: 1em
    list-style-type: none
    border-radius: 10px
    .profile-menu-item
      display: flex
      align-items: center
      justify-content: center
      margin-top: .4em
      cursor: pointer
      padding: .5em
      transition: all .2s
      &:hover
        background: orange
        border-radius: 10px
        transition: all .2s
      .fa
        margin-right: .3em